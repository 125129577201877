import './heart.js'
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import store from './store.js'
import VueRouter from 'vue-router'
import PortalVue from 'portal-vue'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import routeProvder from './routes.mjs'

const routes = routeProvder({
    Dashboard: () => import("./components/Dashboard"),
    PageEditor: () => import("./components/PageEditor"),
    Login: () => import("./Login"),
    Pages: () => import("./components/Pages"),
    StockSubscriptions: () => import("./components/StockSubscriptions"),
    NewsletterSubscriptions: () => import("./components/NewsletterSubscriptions"),
    Emails: () => import("./components/Emails"),
    Releases: () => import("./components/Releases"),
    Collections: () => import("./components/Collections"),
    CollectionEditor: () => import("./components/CollectionEditor"),
    Synchronizations: () => import("./components/Synchronizations"),
    Orders: () => import("./components/Orders"),
    Order: () => import("./components/OrderWrapper"),
    StickyEvents: () => import("./components/StickyEvents"),
    Bulks: () => import("./components/Bulks"),
    BulkEditor: () => import("./components/BulkEditor"),
    Customers: () => import("./components/Customers"),
    WebhookLogs: () => import("./components/WebhookLogs"),
    Carts: () => import("./components/Carts"),
    Visitors: () => import("./components/Visitors"),
    Customer: () => import("./components/Customer"),
    Automations: () => import("./components/Automations"),
    Reviews: () => import("./components/Reviews"),
    Faqs: () => import("./components/Faqs"),
    FaqEditor: () => import("./components/FaqEditor"),
    Automation: () => import("./components/Automation"),
    AutomationCreate: () => import("./components/AutomationCreate"),
    AutomationDocumentation: () => import("./components/AutomationDocumentation"),
    OrderingStrategiesDocumentation: () => import("./components/OrderingStrategiesDocumentation"),
    EmailDocumentation: () => import("./components/EmailDocumentation"),
    ReviewsDocumentation: () => import("./components/ReviewsDocumentation"),
    AutomationSimulations: () => import("./components/AutomationSimulations"),
    AutomationSimulation: () => import("./components/AutomationSimulation"),
    Blog: () => import("./components/Blog"),
    BlogEditor: () => import("./components/BlogEditor"),
    Products: () => import("./components/Products"),
    OrdersCategoryStats: () => import("./components/OrdersCategoryStats"),
    Product: () => import("./components/Product"),
    Categories: () => import("./components/Categories"),
    CouponsDocumentation: () => import("./components/CouponsDocumentation"),
    CategoriesProductContentsEditor: () => import("./components/CategoriesProductContentsEditor"),
    OrderingStrategies: () => import("./components/OrderingStrategies"),
    OrderingStrategy: () => import("./components/OrderingStrategy"),
})

import "./assets/scss/main.scss";

Vue.config.productionTip = false

Vue.use(PortalVue)
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(Toast, {
    pauseOnFocusLoss: false,
});

import TextInput from "./components/widgets/inputs/text-input.vue";
import CheckboxInput from "./components/widgets/inputs/checkbox-input.vue";
import SelectInput from "./components/widgets/inputs/select-input.vue";
import CtaSelector from "./components/widgets/helpers/ctaSelector.vue";
import ThemeSelector from "./components/widgets/helpers/themeSelector.vue";
import AlignSelector from "./components/widgets/helpers/alignSelector.vue";
import groupInput from "./components/widgets/inputs/group-input.vue";
import wysiwygInput from "./components/widgets/inputs/wysiwyg-input.vue";

Vue.component('textInput', TextInput);
Vue.component('checkboxInput', CheckboxInput);
Vue.component('selectInput', SelectInput);
Vue.component('ctaSelector', CtaSelector);
Vue.component('themeSelector', ThemeSelector);
Vue.component('alignSelector', AlignSelector);
Vue.component('groupInput', groupInput);
Vue.component('wysiwygInput', wysiwygInput);

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})

// Auth middleware
router.beforeEach((to, from, next) => {
    if (to.name !== 'login' && !store.state.auth.user) {
        next({ name: 'login' });
    } else {
        next();
    }
});

new Vue({
    render: h => h(App),
    mounted() {
        setTimeout(() => {
            this.$store.dispatch("syncAspects");
            this.$store.dispatch("syncProducts");
        }, 10)
    },
    store,
    router,
}).$mount('#app')
