

const routeProvider = (components) => {
    return [
        {
            path: '/',
            component: components.Dashboard,
            name: 'dashboard',
            meta: {
                label: 'Dashboard',
                icon: 'far fa-analytics',
                group: 'sales',
            }
        },
        {
            path: '/orders',
            component: components.Orders,
            name: 'orders',
            meta: {
                label: 'Orders',
                icon: 'fas fa-box-open',
                group: 'sales',
            }
        },
        {
            path: '/orders/:id',
            component: components.Order,
            name: 'order',
            meta: {
                label: 'Order',
                icon: 'fas fa-box-open',
                hidden: true,
                group: 'sales',
            }
        },
        // Enable order items stats by category
        {
            path: '/orders-stats-category',
            component: components.OrdersCategoryStats,
            name: 'orderItemsStatsByCategory',
            meta: {
                label: 'Orders Stats',
                icon: 'far fa-box',
                hidden: true,
            }
        },
        {
            path: '/customers',
            component: components.Customers,
            name: 'customers',
            meta: {
                label: 'Customers',
                icon: 'fas fa-user',
                group: 'sales',
            }
        },
        {
            path: '/customers/:id',
            component: components.Customer,
            name: 'customer',
            meta: {
                label: 'Customer',
                icon: 'fas fa-box-open',
                hidden: true,
            }
        },
        {
            path: '/visitors',
            component: components.Visitors,
            name: 'visitors',
            meta: {
                label: 'Carts',
                icon: 'fas fa-eye',
                group: 'sales',
                hidden: true,
            }
        },
        {
            path: '/carts',
            component: components.Carts,
            name: 'carts',
            meta: {
                label: 'Carts',
                icon: 'fas fa-shopping-basket',
                group: 'sales',
            }
        },
        {
            path: '/pages',
            component: components.Pages,
            name: 'pages',
            meta: {
                label: 'Pages',
                icon: 'far fa-file-alt',
                group: 'content',
            }
        },
        {
            path: '/pages/:id',
            component: components.PageEditor,
            name: 'page',
            meta: {
                hidden: true,
            }
        },
        {
            path: '/blog',
            component: components.Blog,
            name: 'blog',
            meta: {
                label: 'Blog',
                icon: 'far fa-books',
                group: 'content',
            }
        },
        {
            path: '/blog/create',
            component: components.BlogEditor,
            name: 'blogEditorCreate',
            meta: {
                hidden: true,
            }
        },
        {
            path: '/blog/:id/edit',
            component: components.BlogEditor,
            name: 'blogEditorEdit',
            meta: {
                hidden: true,
            }
        },
        {
            path: '/stock-subs',
            component: components.StockSubscriptions,
            name: 'stock-subs',
            meta: {
                label: 'Stock Subs',
                icon: 'fa-solid fa-star-shooting',
                group: 'sales',
            }
        },
        {
            path: '/newsletter-subs',
            component: components.NewsletterSubscriptions,
            name: 'newsletter-subs',
            meta: {
                label: 'Newsletter Subs',
                icon: 'fa-solid fa-newspaper',
                group: 'sales',
            }
        },
        {
            path: '/stickies/:id/events',
            component: components.StickyEvents,
            name: 'stickyEvents',
            meta: {
                hidden: true
            }
        },
        {
            path: '/login',
            component: components.Login,
            name: 'login',
            meta: {
                hidden: true,
                label: 'login',
                icon: 'far fa-books',
            }
        },
        {
            path: '/categories',
            component: components.Categories,
            name: 'categories',
            meta: {
                label: 'Categories',
                icon: 'far fa-grid-dividers',
                group: 'content',
            }
        },
        {
            path: '/categories/:id/product-contents-editor',
            component: components.CategoriesProductContentsEditor,
            name: 'categoriesProductContentsEditor',
            meta: {
                hidden: true,
            }
        },
        {
            path: '/collections',
            component: components.Collections,
            name: 'collections',
            meta: {
                label: 'Collections',
                icon: 'far fa-head-vr',
                group: 'content',
            }
        },
        {
            path: '/collections/:id',
            component: components.CollectionEditor,
            name: 'collection',
            meta: {
                hidden: true,
            }
        },
        {
            path: '/bulks',
            component: components.Bulks,
            name: 'bulks',
            meta: {
                label: 'Bulks',
                icon: 'fa-solid fa-chart-pyramid',
                group: 'content',
            }
        },
        {
            path: '/bulks/:id',
            component: components.BulkEditor,
            name: 'bulk',
            meta: {
                hidden: true,
            }
        },
        {
            path: '/ordering-strategies',
            component: components.OrderingStrategies,
            name: 'strategy-ordering',
            meta: {
                label: 'Ordering Strategies',
                icon: 'fas fa-sort',
                group: 'content',
            }
        },
        {
            path: '/ordering-strategies/docs',
            component: components.OrderingStrategiesDocumentation,
            name: 'strategy-ordering-docs',
            meta: {
                hidden: true,
            }
        },
        {
            path: '/ordering-strategies/:id',
            component: components.OrderingStrategy,
            name: 'strategy-ordering',
            meta: {
                label: 'Ordering Strategies',
                icon: 'fas fa-sort',
                hidden: true,
                group: 'content',
            }
        },

        {
            path: '/products',
            component: components.Products,
            name: 'products',
            meta: {
                label: 'Products',
                icon: 'far fa-hand-paper',
                group: 'content',
            }
        },
        {
            path: '/products/:id',
            component: components.Product,
            name: 'product',
            meta: {
                hidden: true,
            }
        },
        {
            path: '/synchronizations',
            component: components.Synchronizations,
            name: 'synchronizations',
            meta: {
                label: 'Synchronizations',
                icon: 'far fa-sync',
                group: 'dev',
            }
        },

        {
            path: '/emails',
            component: components.Emails,
            name: 'emails',
            meta: {
                label: 'Emails',
                icon: 'fa-regular fa-envelope',
                group: 'dev',
            }
        },
        {
            path: '/emails/docs',
            component: components.EmailDocumentation,
            name: 'emailsDocumentation',
            meta: {
                hidden: true,
            }
        },
        {
            path: '/automations',
            component: components.Automations,
            name: 'automations',
            meta: {
                label: 'Automations',
                icon: 'far fa-cogs',
                group: 'dev',
            }
        },
        {
            path: '/automations/docs',
            component: components.AutomationDocumentation,
            name: 'automationsDocumentation',
            meta: {
                hidden: true,
            }
        },
        {
            path: '/automations/create',
            component: components.AutomationCreate,
            name: 'automationsCreate',
            meta: {
                hidden: true,
            }
        },
        {
            path: '/automations/:id/simulations/:simulation',
            component: components.AutomationSimulation,
            name: 'automationsSimulationsShow',
            meta: {
                hidden: true,
            }
        },
        {
            path: '/automations/:id/simulations',
            component: components.AutomationSimulations,
            name: 'automationsSimulations',
            meta: {
                hidden: true,
            }
        },
        {
            path: '/automations/:id',
            component: components.Automation,
            name: 'automation',
            meta: {
                hidden: true,
            }
        },
        {
            path: '/releases',
            component: components.Releases,
            name: 'releases',
            meta: {
                label: 'Releases',
                icon: 'fa-regular fa-ship',
                group: 'dev',
            }
        },
        {
            path: '/webhook-logs',
            component: components.WebhookLogs,
            name: 'webhookLogs',
            meta: {
                label: 'Webhook Logs',
                icon: 'fas fa-memo',
                group: 'dev',
            }
        },
        {
            path: '/reviews',
            component: components.Reviews,
            name: 'reviews',
            meta: {
                label: 'Reviews',
                icon: 'far fa-star',
                group: 'content',
            }
        },
        {
            path: '/reviews/docs',
            component: components.ReviewsDocumentation,
            name: 'reviewsDocumentation',
            meta: {
                hidden: true,
            }
        },
        {
            path: '/faqs',
            component: components.Faqs,
            name: 'faqs',
            meta: {
                label: 'FAQ',
                icon: 'fa-solid fa-comments-question',
                group: 'content',
            }
        },
        {
            path: '/faqs/create',
            component: components.FaqEditor,
            name: 'faqsEditorCreate',
            meta: {
                hidden: true,
            }
        },
        {
            path: '/faqs/:id/edit',
            component: components.FaqEditor,
            name: 'faqsEditorEdit',
            meta: {
                hidden: true,
            }
        },

        {
            path: '/coupons',
            component: components.CouponsDocumentation,
            name: 'coupons',
            meta: {
                label: 'coupons',
                icon: 'far fa-sack-dollar',
                group: 'dev',
            }
        },
    ]
}

export default routeProvider
